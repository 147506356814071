import React from 'react'
import Layout from '../components/Layout'
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/solid'
import { Devtools } from '@ui-devtools/tailwind'
// import Services from '../components/WhatWeDo'

const salesAndAssessments = [
    {
        id: 1,
        name: 'Analysis & Diagnostics',
        description:
            'Transformer Oil change, Filtering of impurities, hydrocarbon gases and moisture, Oil condition and analysis, Switching to environmentally friendlier fluid, Scrap oil disposal',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'Assessments and Testing',
        description:
            'In the field or at our facilities, Transformer failure root cause analysis, Tank strength and protective coatings, Internal winding and insulation',
        icon: ScaleIcon,
    },
    {
        id: 3,
        name: 'Transfers are instant',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: LightningBoltIcon,
    },
]
const repairAndDisposal = [
    {
        id: 1,
        name: 'Mobile notifications',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: AnnotationIcon,
    },
    {
        id: 2,
        name: 'Reminder emails',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: MailIcon,
    },
]

export default function WhatWeDoPage() {
    return (
        <div className="min-h-screen bg-grey-100">
            <Devtools>
                <Layout>
                    <main>
                        <section id="what-we-do-header">
                            <div className="relative bg-red-500">
                                <div className="absolute inset-0">
                                    <img
                                        className="object-cover w-full h-full"
                                        src="https://res.cloudinary.com/minimum-viable/image/upload/c_scale,q_50,w_2000/v1621340768/virgintransformers/draughtsman_lx2cai.jpg"
                                        alt=""
                                    />
                                    <div className="absolute inset-0 bg-red-500 mix-blend-multiply" aria-hidden="true" />
                                </div>
                                <div className="relative px-4 py-24 mx-auto text-center max-w-7xl sm:py-32 sm:px-6 lg:px-8">
                                    <h1 className="text-4xl font-medium tracking-tight text-white border-white sm:text-5xl lg:text-6xl">What We Do</h1>
                                </div>
                            </div>
                        </section>

                        <section id="services">
                            <div className="py-16 overflow-hidden bg-gray-50 lg:py-24">
                                <div className="relative max-w-xl px-4 mx-auto sm:px-6 lg:px-8 lg:max-w-7xl">
                                    <svg
                                        className="absolute hidden transform -translate-x-1/2 lg:block left-full -translate-y-1/4"
                                        width={404}
                                        height={784}
                                        fill="none"
                                        viewBox="0 0 404 784"
                                        aria-hidden="true"
                                    >
                                        <defs>
                                            <pattern
                                                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                            </pattern>
                                        </defs>
                                        <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                                    </svg>

                                    <div className="relative">
                                        <h2 className="text-3xl font-medium leading-8 tracking-tight text-center text-gray-600 sm:text-4xl">
                                            Transformer Sales, Services and Support
          </h2>
                                        <p className="max-w-3xl mx-auto mt-4 text-xl text-center text-gray-500">
                                            We pride ourselves in being highly experienced in the manufacture, sale, servicing and support of a wide range
                                            of transformer related products. Our clients look to us for expert advice when it comes to acquiring or maximizing the performance and life expectancy of their transformers and related components.
          </p>
                                    </div>

                                    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                                        <div className="relative">
                                            <h3 className="text-2xl font-medium tracking-tight text-gray-600 sm:text-3xl">
                                                Sales and Assessments
            </h3>
                                            <p className="mt-3 text-lg text-gray-500">
                                                We stock a wide variety of coils, solenoids, drain valves, break coils, clutch coils for motors, Transformers from 200VA-25KVA, Air and Oil cool, multiple Tapping on Transformers
            </p>

                                            <dl className="mt-10 space-y-10">
                                                {salesAndAssessments.map((item) => (
                                                    <div key={item.id} className="relative">
                                                        <dt>
                                                            <div className="absolute flex items-center justify-center w-12 h-12 text-white bg-red-500 rounded-md">
                                                                <item.icon className="w-6 h-6" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                                                        </dt>
                                                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                                    </div>
                                                ))}
                                            </dl>
                                        </div>

                                        <div className="relative mt-10 -mx-4 lg:mt-0" aria-hidden="true">
                                            <svg
                                                className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                                                width={784}
                                                height={404}
                                                fill="none"
                                                viewBox="0 0 784 404"
                                            >
                                                <defs>
                                                    <pattern
                                                        id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                                        x={0}
                                                        y={0}
                                                        width={20}
                                                        height={20}
                                                        patternUnits="userSpaceOnUse"
                                                    >
                                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                                    </pattern>
                                                </defs>
                                                <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                                            </svg>
                                            <img
                                                className="relative mx-auto"
                                                width={490}
                                                src="https://res.cloudinary.com/minimum-viable/image/upload/v1620724819/virgintransformers/transformer_omfvmn.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                    <svg
                                        className="absolute hidden transform translate-x-1/2 translate-y-12 lg:block right-full"
                                        width={404}
                                        height={784}
                                        fill="none"
                                        viewBox="0 0 404 784"
                                        aria-hidden="true"
                                    >
                                        <defs>
                                            <pattern
                                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                                x={0}
                                                y={0}
                                                width={20}
                                                height={20}
                                                patternUnits="userSpaceOnUse"
                                            >
                                                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                            </pattern>
                                        </defs>
                                        <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                                    </svg>

                                    {/* <div className="relative mt-12 sm:mt-16 lg:mt-24">
                                        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                                            <div className="lg:col-start-2">
                                                <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Always in the loop</h3>
                                                <p className="mt-3 text-lg text-gray-500">
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus,
                                                    cum deleniti sunt in labore nihil quod quibusdam expedita nemo.
              </p>

                                                <dl className="mt-10 space-y-10">
                                                    {repairAndDisposal.map((item) => (
                                                        <div key={item.id} className="relative">
                                                            <dt>
                                                                <div className="absolute flex items-center justify-center w-12 h-12 text-white bg-indigo-500 rounded-md">
                                                                    <item.icon className="w-6 h-6" aria-hidden="true" />
                                                                </div>
                                                                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                                                            </dt>
                                                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                                        </div>
                                                    ))}
                                                </dl>
                                            </div>

                                            <div className="relative mt-10 -mx-4 lg:mt-0 lg:col-start-1">
                                                <svg
                                                    className="absolute transform -translate-x-1/2 translate-y-16 left-1/2 lg:hidden"
                                                    width={784}
                                                    height={404}
                                                    fill="none"
                                                    viewBox="0 0 784 404"
                                                    aria-hidden="true"
                                                >
                                                    <defs>
                                                        <pattern
                                                            id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                                            x={0}
                                                            y={0}
                                                            width={20}
                                                            height={20}
                                                            patternUnits="userSpaceOnUse"
                                                        >
                                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                                        </pattern>
                                                    </defs>
                                                    <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                                                </svg>
                                                <img
                                                    className="relative mx-auto"
                                                    width={490}
                                                    src="https://tailwindui.com/img/features/feature-example-2.png"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </main>


                </Layout>
            </Devtools >
        </div >
    );
}
